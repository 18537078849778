<template>
  <b-modal
    id="subscribes-popup"
    modal-class="modal-add"
    :static="true"
    v-model="showModalSubscribe"
    aria-labelledby="subscribes-popup___BV_modal_title_"
  >
    <div slot="modal-header">
      <h4>У вас закінчився термін підписки!!!</h4>
    </div>
    <div slot="default">
      <p class="text-subsc">
        Термін доступу до Бази закінчується для продовження використання Бази,
        необхідно зробити оплату!
      </p>
      <div
        class="cabinet-pay-btn d-flex align-items-end justify-content-center"
        v-html="paymentForm"
      ></div>
    </div>
    <div slot="modal-footer"></div>
  </b-modal>
</template>

<script>
export default {
  name: 'SubscribesPopup.vue',
  computed: {
    showModalSubscribe: {
      set(val) {
        this.$store.commit('SUBSCRIBES', val);
      },
      get() {
        return this.$store.state.profile.subscribe;
      }
    },
    paymentForm() {
      const paymentForms = this.$store.state.profile.userMe.payment_forms;
      if (paymentForms && paymentForms.realty_access) {
        return paymentForms.realty_access;
      }

      return null;
    }
  }
};
</script>

<style scoped>
.cabinet-pay-btn {
  width: 100%;
}
.text-subsc {
  text-align: center;
  font-size: 14px;
}
</style>
